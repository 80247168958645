.imagesLogo{
	width:400px;
	float: right;
}

#space, #space2{
	padding: 0 0 20px 0;
}

.textContainerExperience{
    text-align: left;
    font-size: 25px;
    max-width: 500px;
}

.separator{
    padding: 0 0 0 40px;
}