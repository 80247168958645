.vContainer{
	display: flex;
	flex-direction: column;
}

.hContainer{
	display: flex;
	flex-direction: row;
	width: 90%;
	margin: 0 auto;
    padding-top: 5em;
}
.videoProject{
	width: 90%;
    min-width: 400px;
    max-width: 500px;
    height: 300px;
}

.TitletextContainer{
	margin: 0 auto;
	text-align: left;
}

.textContainer{
	text-align: justify;
    font-size: 20px;
    max-height: 300px;
    max-width: 500px;
    width: 90%;
}

.separator{
	margin: 40px 50px 0 50px;
}

.sizeContainerL{
    width: 50%;
    position: relative;
    height: 400px;
    text-align: right;
}

.sizeContainerR{
    width: 50%;
    padding: 0 0 0 70px;
}

.downloadContainer{
	height: 40px;
	width: 100%;
}

.downloadLink{
	display: inline-block;
	float: left;
	padding-right: 20px;
    font-size: 20px;
}

.downloadInfo{
	text-align: left;
    font-size: 20px;
}
