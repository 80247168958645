.dialog{
    position: fixed;
    margin: 0;
    padding: 0;
    border: none;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: rgba(0,0,0,0.25);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
}   

.image{
    position:relative;
    top: 15%;
    width: 75%;
    height: auto;
    max-height: 720px;
    max-width: 1280px;
    z-index: 8;
    opacity: 1;
}

.imageSetup{
    width: 90%;
    height: 300;
    min-width: 400px;
    max-width: 500px;
}