#VSContainer{
	display: flex;
	flex-direction: column;
}

.HSContainer{
	display: flex;
	flex-direction: row;
	padding: 10px 0 10px 0;
	margin: 0 auto;
	width: 80%;
    max-width: 1000px; 
}

.ItemSkill{
	width: 100%;
	height: 100px;
    line-height: 100px;
	background-color: black;
	color: #e8e7e6;
	padding: 0 10px 0 10px;
	margin: 0 10px 0 10px;
    vertical-align: middle;
    font-size: 40px;
}
