#presentation {
    height: auto;
    width: 85%;
    display: inline-block;
    font-size: 25px;
    text-align: justify;
    min-width: 200px;
    max-height: 300px;
    max-width: 800px;
}

#presentationSection{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.videoPresentation{
    width: 90%;
    min-width: 480px;
    max-width: 1000px;
    height: 600px;
}