html{
	font-family: 'PT Sans', sans-serif;
	background-color: #e8e7e6;
    margin: 0px;
    padding: 0px;
}

body{
	font-size: 16px;
  	margin:0;
    padding: 0;
  	width: 100%;
  	min-width: 900px;
}

#video-container {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
}

.video{
    width: 100%; 
    height: 100vh;
    object-fit: cover;
}

.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: repeating-linear-gradient(to top, rgba(34,35,46,0.95), 
  rgba(0,0,0,0.4), rgba(34,35,46,0.95));
}

.textIntro{
    position: absolute;
    width: 100%;
    top: 40%;
    color: white;
    font-size: 50px;
    font-weight: bold;
}

.images{
	width: 100%;
}

.imageProject{
    width: 30px;
}

:root {
  	--off-white: black;
}

.nav {
    background-color: black;
  	position: fixed;
  	top: 0px;
  	width: 100%;
  	height: 80px;
  	z-index: 5;
  	box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.nav-logo {
  	height: 60px;
  	width: 50px;
}

.nav-logo:hover {
  	cursor: pointer;
}

.nav .nav-content {
  	max-width: 900px;
 	padding: 0rem 3rem;
  	margin: 0 auto;
  	display: flex;
  	justify-content: space-between;
  	align-items: center;
  	height: 100%;
}

.nav-item {
  	display: inline;
  	margin-left: 2rem;
  	color: white;
    font-size: 21px;
}	


.nav-item:hover {
  	color: #ffcc00;
  	cursor: pointer;
}

#nav-item-name {
  	display: inline;
  	margin-left: 2rem;
  	color: white;
    font-size: 25px;
}	

#nav-item-name:hover {
  	color: #ffcc00;
  	cursor: pointer;
}	

.section-content{
	text-align: center;
	padding: 20px 0 40px 0;
	opacity: 0;
  	transform: translateY(20vh);
 	visibility: hidden;
 	transition: opacity 0.6s ease-out, transform 0.5s ease-out;
 	will-change: opacity, visibility;
}

.section-content.is-visible {
  	opacity: 1;
  	transform: none;
  	visibility: visible;
}

#footContainer{
	display: flex;
	flex-direction: row;
	background-color: black;
	height: 250px;
}

.foot{
	color: #e8e7e6;
	text-align: center;
	width: 400px;
	font-size: 20px;
	margin: 0 0 0 0;
}

.footRowContainer{
	width: 100%;
	height: 40px;
	margin: auto 0;
    position: relative;
    padding: 0 50px 0 50px;
}

#link{
	display: inline-block;
	width: 30px;
  	height: 30px;
  	right: 0;
}

#email{
	text-align: left;
	color: #e8e7e6;
	width: 90%;
	font-size: 20px;
    margin: 0;
    padding: 0 0 0 10px;
}

#icon{
 	width: 30px;
  	height: 30px;
}

.icon_mail{
    display: flex;
	flex-direction: row;
    float: right;
}
#imageLink3, #section4, #section5{
    position: relative;
    z-index: -1;
}