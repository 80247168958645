#vContainer{
	display: flex;
	flex-direction: column;
}

.hContainerStudies{
	display: flex;
	flex-direction: row;
    width: 70%;
    max-width: 800px;
    position: relative;
    margin: 0 auto;
    padding: 10px;
}

.sizeContainerLeft{
	width: 20%;
}

.sizeContainerRight{
	width: 70%;
    max-width: 500px;
}

.titleParagraph{
    font-size: 25px;
}

.textContainerStudies{
    font-size: 25px;
    text-align: justify;
}

.separator{
	margin: 40px 50px 0 50px;
    font-size: 40px;
}